import { serverOwner } from "./config"


export let isCreditVisible =()=>{

    let showFooter = true

    if (window.location.hostname.includes('gamerheart.in')) {
      showFooter = false
    }

    if(serverOwner =="client"){
      showFooter = false
    }

    return showFooter
}